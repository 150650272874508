<template>
    <div>
        <div class="SecondTitleRegistration" style="display: flex;justify-content: center;">
            <span>{{ $t('jpnRegistration.counter') }} : {{ this.storeName }}</span>
        </div>
        <van-cell-group style="margin-top: 20px">
            <van-form>
                <van-field
                    required
                    type="textarea"
                    :autosize=true
                    rows="1"
                    :label="this.$t('jpnRegistration.localLastName')"
                    :placeholder="this.$t('common.enter')"
                    v-model="info.localLastName"
                    :error-message="errMessageLastName"
                    @input="validatorLastName()"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    required
                    type="textarea"
                    :autosize=true
                    rows="1"
                    :label="this.$t('jpnRegistration.localFirstName')"
                    :placeholder="this.$t('common.enter')"
                    v-model="info.localFirstName"
                    :error-message="errMessageFirstName"
                    @input="validatorFirstName()"
                    @focus="validatorLastName()"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    required
                    type="textarea"
                    :autosize=true
                    rows="1"
                    maxlength="15"
                    :label="this.$t('jpnRegistration.localLastName2')"
                    :placeholder="this.$t('common.enter')"
                    v-model="info.localLastName2"
                    :error-message="errMessageLastName2"
                    @input="validatorLastName2()"
                    @focus="validatorFirstName(),validatorLastName()"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    required
                    type="textarea"
                    :autosize=true
                    rows="1"
                    maxlength="15"
                    :label="this.$t('jpnRegistration.localFirstName2')"
                    :placeholder="this.$t('common.enter')"
                    v-model="info.localFirstName2"
                    :error-message="errMessageFirstName2"
                    @input="validatorFirstName2()"
                    @focus="validatorFirstName(), validatorLastName(), validatorLastName2()"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    required
                    :label="this.$t('jpnRegistration.phone')"
                    :placeholder="this.$t('common.enter')"
                    type="number"
                    maxlength="11"
                    v-model="info.phone"
                    :error-message="errMessagePhone"
                    @input="validatorPhone()"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2()"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    required
                    :disabled=true
                    type="textarea"
                    :autosize=true
                    rows="1"
                    :label="this.$t('jpnRegistration.email')"
                    :placeholder="this.$t('common.enter')"
                    v-model="info.email"
                    :error-message="errMessageEmail"
                    @input="validatorEmail()"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone()"
                    label-width="125"
                    input-align="right"
                />
                
                <div class="birth-style">
                    <span style="white-space: nowrap;">{{ this.$t('jpnRegistration.birth')}}</span>
                    <van-field>
                    <template #input>
                        <BirthView class="birth-view"
                            :birth="info.birth" country="JPN" :requiredDay=true :requiredMonth=true :classYMD=true
                            @change="validatorBirthDay(), validatorBirthMonth(), validatorFirstName(), validatorLastName(), validatorFirstName2(), validatorLastName2(), validatorPhone(), validatorEmail()"
                        />
                    </template>
                    </van-field>
                </div>
                <div class="errMsg-birth" v-show="!this.errMessageBirthDay == ''">
                    {{ this.errMessageBirthDay }}
                </div>
                <div class="errMsg-birth" v-show="!this.errMessageBirthMonth == ''">
                    {{ this.errMessageBirthMonth }}
                </div>
                
                <div class="gender-style">
                    <div style="width: 125px;">
                        <span style="white-space: nowrap;">{{ this.$t('jpnRegistration.gender')}}</span>
                    </div>
                    <div style="padding-right: 16px;">
                        <van-dropdown-menu>
                            <van-dropdown-item :title="genderOptions.find(i => i.value == info.gender)?.text || $t('jpnRegistration.select')" v-model="info.gender" :options="genderOptions" />
                        </van-dropdown-menu>
                    </div>
                </div>

                <van-field
                    type="number"
                    maxlength="10"
                    minlength="5"
                    v-model="info.zipCode"
                    :error-message="errMessageZipCode"
                    @input="validatorZipCode()"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                    :label="this.$t('jpnRegistration.zipCode')"
                    :placeholder="this.$t('common.enter')"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    type="textarea"
                    :autosize=true
                    rows="1"
                    v-model="info.prefectures"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                    @input="validatorPrefectures()"
                    :label="this.$t('jpnRegistration.prefectures')"
                    :placeholder="this.$t('common.enter')"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    type="textarea"
                    :autosize=true
                    rows="1"
                    v-model="info.municipalities"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                    @input="validatorMunicipalities()"
                    :label="this.$t('jpnRegistration.municipalities')"
                    :placeholder="this.$t('common.enter')"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    type="textarea"
                    :autosize=true
                    rows="1"
                    v-model="info.address2"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                    @input="validatorAddress2()"
                    :label="this.$t('jpnRegistration.address1')"
                    :placeholder="this.$t('common.enter')"
                    label-width="125"
                    input-align="right"
                />
                <van-field
                    type="textarea"
                    :autosize=true
                    rows="1"
                    v-model="info.address3"
                    @focus="validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                    @input="validatorAddress3()"
                    :label="this.$t('jpnRegistration.address2')"
                    :placeholder="this.$t('common.enter')"
                    label-width="140"
                    input-align="right"
                />

                <div class="second-tips">
                    <div>
                        <input 
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.personalInfoAgreeYN"
                            @change="validatorPersonalInfo(),validatorFirstName(),validatorLastName(),validatorFirstName2(),validatorLastName2(),validatorPhone(),validatorEmail(),validatorBirthDay(),validatorBirthMonth()"
                        />
                    </div>
                    <div class="agreeStatement" v-html='$t("jpnRegistration.pInfoAgree").format(privacyPolicyLink)'></div>
                </div>
                <div style="color:#ff0000;margin-left: 45px;">
                    {{ this.errMessagePersonalInfo }}
                </div>
                <div class="second-tips">
                    <div >
                        <input 
                            class="agreeCheckBox"
                            type="checkbox"
                            v-model="info.promotionAgreeYN"
                            @change="validatorPersonalInfo()"
                        />
                    </div>
                    <div class="agreeStatement" v-html='promotionText'></div>
                </div>
                <div style="margin-top: 20px">
                    <van-button
                        :disabled="submitDisabled"
                        size="large"
                        round
                        class="button-submit"
                        @click="submit()"
                        :loading="loading"
                    >{{ $t("common.submit") }}</van-button>
                </div>
            </van-form>
        </van-cell-group>
    </div>
</template>

<script type="text/javascript" src="./../../../lib/kana-1.0.7-min.js"></script>
<script>
import {
    Button,
    Cell,
    CellGroup,
    DropdownItem,
    DropdownMenu,
    Field,
    Switch,
    Checkbox,
    CheckboxGroup,
    Form,
    Radio,
    RadioGroup,
    DatetimePicker,
    Popup,
    Dialog,
} from "vant";
import kana from "./../../../lib/kana-1.0.7-min.js";
import { odsAddOrUpdate, getAddress, getSession } from "@/api/eformApi";
import mixins from "./../mixin/Registration.js";
import { Toast } from "vant";
export default {
    name: "Registration",
    mixins: [mixins],
    components: {
        [Popup.name]: Popup,
        [DatetimePicker.name]: DatetimePicker,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Form.name]: Form,
        [CellGroup.name]: CellGroup,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        [Switch.name]: Switch,
        [Cell.name]: Cell,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Button.name]: Button,
        [Field.name]: Field,
        [Dialog.Component.name]: Dialog.Component,
        BirthView: () => import("./../components/BirthView"),
    },
    data() {
        return {
            info: {
                localFirstName: "",
                localLastName: "",
                localFirstName2: "",
                localLastName2: "",
                phone: "",
                email: "",
                birth: {
                    day: "",
                    month: "",
                    year: "",
                },
                gender: "",
                address1: "",
                address2: "",
                address3: "",
                zipCode: "",
                prefectures: "",
                municipalities: "",
                personalInfoAgreeYN: false,
                promotionAgreeYN: true,
            },
            loading: false,
            errMessageFirstName: "",
            errMessageLastName: "",
            errMessageFirstName2: "",
            errMessageLastName2: "",
            errMessagePhone: "",
            errMessageEmail: "",
            errMessageBirthDay: "",
            errMessageBirthMonth: "",
            errMessageZipCode: "",
            errMessagePersonalInfo: "",
            genderOptions: [
                { text: this.$t("jpnRegistration.male"), value: "mle" },
                { text: this.$t("jpnRegistration.female"), value: "fml" },
            ],
            specialCharsRegex:  /[`:._~!@#$%^&*() \\+ =<>?"{}|, \\/ ;' \\ [ \] ·~！@#￥%……&*（）—— \\+ ={}|《》？：“”【】、；‘’，。、-]/g,
            emailRegex: /^[0-9a-zA-Z-_.]*@[0-9a-zA-Z-_.]*[.][0-9a-zA-Z]{1,10}$/,
            zipCodeRegex: /^[0-9]{5,10}$/,
            name2Regex: /[^0-9a-zA-Zｦ-ﾟ]/g,
            addresses: null,
            sessionKey: 0,
        };
    },
    computed: {
        privacyPolicyLink() {
            if (this.brandConfig.privacyPolicy) {
                return `<span><a style="color: blue" href="${this.brandConfig.privacyPolicy}">${this.$t("common.privacyPolicy")}</a></span>`;
            } else {
                return `<span>${this.$t("common.privacyPolicy")}</span>`;    
            }
        },
        promotionText() {
            return `<span>${this.$t("jpnRegistration.promotionAgree1")}</span><br/>
            <span style="color: red">${this.$t("jpnRegistration.promotionAgree2")}</span>`;
        },
        currentLocale() {
            return this.$root.$i18n.locale;
        },
        submitDisabled() { 
            return !this.info.personalInfoAgreeYN || this.errMessageFirstName != "" || this.errMessageLastName != "" ||
                this.errMessageFirstName2 != "" || this.errMessageLastName2 != "" || this.errMessagePhone != "" ||
                this.errMessageEmail != "" || this.errMessageBirthDay != "" || this.errMessageBirthMonth != "" ||
                this.errMessageZipCode != "" || this.errMessagePersonalInfo != ""
        }
    },
    created() { 
        this.sessionKey = this.$route.params.sessionKey
        this.info.email = this.$route.params.email
        console.log(this.sessionKey, "--", this.info.email)
        if (!this.info.email) {
            console.log("flsjflsjflsdjf")
            this.$router.push({
                name: 'EnterEmail',
                params: {
                    lang: this.$route.params.lang,
                    brand: this.$route.params.brand,
                    country: this.$route.params.countryPath
                },
                query: {
                    storeId: this.$route.query.storeId,
                }
            })
            return
        }
        this.getAddress()
    },
    watch: {
        currentLocale: {
            handler() {
                if (this.errMessageFirstName != "") {
                    this.validatorFirstName();
                }
                if (this.errMessageLastName != "") {
                    this.validatorLastName();
                }
                if (this.errMessageFirstName2 != "") {
                    this.validatorFirstName2();
                }
                if (this.errMessageLastName2 != "") {
                    this.validatorLastName2();
                }
                if (this.errMessagePhone != "") {
                    this.validatorPhone();
                }
                if (this.errMessageEmail != "") {
                    this.validatorEmail();
                }
                if (this.errMessageBirthDay != "") {
                    this.validatorBirthDay();
                }
                if (this.errMessageBirthMonth != "") {
                    this.validatorBirthMonth();
                }
                if (this.errMessageZipCode != "") {
                    this.validatorZipCode();
                }
                if (this.errMessagePersonalInfo != "") { 
                    this.validatorPersonalInfo();
                }
                this.genderOptions = [
                    { text: this.$t("jpnRegistration.male"), value: "mle" },
                    { text: this.$t("jpnRegistration.female"), value: "fml" },
            ]
            },
        },
    },
    methods: {
        validatorFirstName() {
            this.errMessageFirstName = ""
            this.info.localFirstName = this.info.localFirstName.replace(this.specialCharsRegex, "")
            if (this.info.localFirstName.trim() == "") {
                this.errMessageFirstName = this.$t("jpnRegistration.errMessageFirstName");
                return false;
            }
            return true;
        },
        validatorLastName() {
            this.errMessageLastName = ""
            this.info.localLastName = this.info.localLastName.replace(this.specialCharsRegex, "")
            if (this.info.localLastName.trim() == "") {
                this.errMessageLastName = this.$t("jpnRegistration.errMessageLastName");
                return false;
            }
            return true;
        },
        validatorFirstName2() {
            this.errMessageFirstName2 = ""
            this.info.localFirstName2 = this.info.localFirstName2.replace(this.specialCharsRegex, "")
            this.info.localFirstName2 = this.info.localFirstName2.toHankanaCase()
            this.info.localFirstName2 = this.replacePianjiaming(this.info.localFirstName2)
            this.info.localFirstName2 = this.info.localFirstName2.replace(this.name2Regex, "")
            
            if (this.info.localFirstName2.trim() == "") {
                this.errMessageFirstName2 = this.$t("jpnRegistration.errMessageFirstName2");
                return false;
            }
            return true;
        },
        validatorLastName2() {
            this.errMessageLastName2 = ""
            this.info.localLastName2 = this.info.localLastName2.replace(this.specialCharsRegex, "")
            this.info.localLastName2 = this.info.localLastName2.toHankanaCase()
            this.info.localLastName2 = this.replacePianjiaming(this.info.localLastName2)
            this.info.localLastName2 = this.info.localLastName2.replace(this.name2Regex, "")
            
            if (this.info.localLastName2.trim() == "") {
                this.errMessageLastName2 = this.$t("jpnRegistration.errMessageLastName2");
                return false;
            }
            return true;
        },
        validatorEmail() {
            this.errMessageEmail = "";
            if (!this.emailRegex.test(this.info.email.trim())) {
                this.errMessageEmail = this.$t("jpnRegistration.errMessageEmail");
                return false;
            }
            return true;
        },
        validatorPhone() {
            this.errMessagePhone = "";
            if (this.info.phone.trim() == "" || this.info.phone.trim().length < 11) {
                this.errMessagePhone = this.$t("jpnRegistration.errMessagePhone");
                return false;
            }
            return true;
        },
        validatorBirthDay() {
            this.errMessageBirthDay = ""
            if (this.info.birth.day == "") {
                this.errMessageBirthDay = this.$t("jpnRegistration.errMessageBirthDay");
                return false;
            } 
            return true;
        },
        validatorBirthMonth() {
            this.errMessageBirthMonth = ""
            if (this.info.birth.month == "") {
                this.errMessageBirthMonth = this.$t("jpnRegistration.errMessageBirthMonth");
                return false;
            } 
            return true;
        },
        validatorZipCode() {
            this.errMessageZipCode = "";
            if (!this.zipCodeRegex.test(this.info.zipCode.trim())) {
                this.errMessageZipCode = this.$t("jpnRegistration.errMessageZipCode");
                this.info.prefectures = ""
                this.info.municipalities = ""
                return false;
            }
            if (this.addresses != null) {
                if (this.addresses[this.info.zipCode.trim()]) {
                    this.info.prefectures = this.addresses[this.info.zipCode.trim()].prefectures
                    this.info.municipalities = this.addresses[this.info.zipCode.trim()].municipalities
                } else {
                    this.info.prefectures = ""
                    this.info.municipalities = ""
                }
            }
            return true;
        },
        validatorPrefectures(){
            // prefectures + municipalities 一共15个字
            this.info.prefectures = this.limitBytes(this.info.municipalities + this.info.prefectures).slice(this.info.municipalities.length)
        },
        validatorMunicipalities(){
            // prefectures + municipalities 一共15个字
            this.info.municipalities = this.limitBytes(this.info.prefectures + this.info.municipalities).slice(this.info.prefectures.length)
        },
        validatorAddress2(){
            this.info.address2 = this.limitBytes(this.info.address2)
        },
        validatorAddress3(){
            this.info.address3 = this.limitBytes(this.info.address3)
        },
        validatorPersonalInfo() {
            this.errMessagePersonalInfo = "";
            if (!this.info.personalInfoAgreeYN) {
                this.errMessagePersonalInfo = this.$t("jpnRegistration.errMessagePersonalInfo");
                return false;
            }
            return true;
        },
        getAddress() {
            getAddress({
                country: this.$route.params.countryPath,
            }).then(res => {
                if (res.success) {
                    this.addresses = res.data
                }
            })
        },
        submit() {
            this.loading = true;
            if (
                !this.validatorFirstName() | !this.validatorLastName() |
                !this.validatorFirstName2() | !this.validatorLastName2() |
                !this.validatorPhone() | !this.validatorEmail() |
                !this.validatorBirthDay() | !this.validatorBirthMonth() |
                !this.validatorPersonalInfo()
            ) {
                this.loading = false;
                return;
            }

            this.info.localFirstName = this.info.localFirstName.trim()
            this.info.localLastName = this.info.localLastName.trim()
            this.info.localFirstName2 = this.info.localFirstName2.trim()
            this.info.localLastName2 = this.info.localLastName2.trim()
            this.info.email = this.info.email.trim()
            this.info.address1 = this.info.prefectures.trim() + this.info.municipalities.trim()
            this.info.address2 = this.info.address2.trim()
            this.info.address3 = this.info.address3.trim()
            this.info.zipCode = this.info.zipCode.trim()

            this.doAddConsumer(this.sessionKey)
        },
        doAddConsumer(sessionKey) {
            odsAddOrUpdate({
                country: this.$route.params.countryPath,
                storeNo: this.$route.query.storeId,
                brandCode: this.$route.params.brand,
                sessionKey: sessionKey,
                ...this.info,
            }).then((res) => {
                if (res.success) {
                    if (res.data.consumerID) {
                        this.$router.push({
                            name: 'QRcodes',
                            params: {
                                custId: res.data.consumerID,
                                storeId: this.$route.query.storeId,
                                lang: this.$route.params.lang,
                                brand: this.$route.params.brand,
                                country: this.$route.params.countryPath,
                                email: this.info.email,
                                // 用于QRcode页面展示
                                localFirstName2: this.info.localFirstName2,
                                localLastName2: this.info.localLastName2,
                            },
                            query: {
                                storeId: this.$route.query.storeId,
                            }
                        })
                    } else {
                        Toast.fail(this.$t('common.toastFail'))
                        this.loading = false;
                    }
                } else if (res.data.exist) {
                    Dialog.alert({
                        message: this.$t("jpnRegistration.alreadyRegistered"),
                        confirmButtonText: this.$t("common.confirm")
                    }).then(() => {
                        this.$router.push({
                            name: 'QRcodes',
                            params: {
                                custId: res.data.data.consumerID,
                                storeId: this.$route.query.storeId,
                                lang: this.$route.params.lang,
                                brand: this.$route.params.brand,
                                country: this.$route.params.countryPath,
                                email: this.info.email,
                                // 用于QRcode页面展示
                                localFirstName2: this.info.localFirstName2,
                                localLastName2: this.info.localLastName2,
                            },
                            query: {
                                storeId: this.$route.query.storeId,
                            }
                        })
                        this.loading = false
                    })
                } else if (res.data.expired) {
                    getSession({
                        country: this.$route.params.countryPath,
                        email: this.info.email,
                    }).then(res => {
                        this.doAddConsumer(res.data)
                     })
                } else {
                    Toast.fail(this.$t('common.toastFail'))
                    this.loading = false;
                }
            }).catch(() => {
                Toast.fail(this.$t('common.toastFail'))
                this.loading = false;
            })
        },
        replacePianjiaming(str) {
            str = str.replace("ヴ", "ｳﾞ")
            str = str.replace("ヰ", "ｲ")
            str = str.replace("ヱ", "ｴ")
            str = str.replace("ョ", "ｮ")
            str = str.replace("ュ", "ｭ")
            str = str.replace("ャ", "ｬ")
            str = str.replace("ヮ", "ﾜ")
            str = str.replace("ヵ", "ｶ")
            str = str.replace("ヶ", "ｹ")
            return str
        },
        replaceSpecialChars(str) {
            if (!str || str == "") {
                return ""
            }
            return str.replace(this.specialCharsRegex, "")
        },
        limitBytes(str) {
            const byteArr = new TextEncoder().encode(str)
            let bytes = byteArr.length
            if (bytes > 45) {
                const byteSubString = byteArr.slice(0, 45)
                return new TextDecoder().decode(byteSubString)
            }
            return str
        },
    },
};
</script>

<style scoped>
* {
    font-family: Helvetica-Regular !important;
}

input.agreeCheckBox {
    transform: scale(1.5);
    margin-top: 33%;
}

.agreeStatement {
    margin-left: 10px;
    margin-right: 24px;
}

.desc-tips-agreeStatement {
    font-size: 15px !important;
}

.second-tips {
    display: inline-flex;
    font-size: 14px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 15px;
    margin-left: 15px;
    line-height: 20px;
    width: 100%;
}

.desc-tips {
    display: inline-flex;
    font-family: Helvetica-Regular;
    font-weight: 400;
    line-height: 20px;
    width: 90%;
    padding-left: 30px;
}

.errMsg-birth {
    color:#ff0000;
    text-align: right;
    padding-right: 15px;
}

.second-no-checkbox-tips {
    margin-left: -10px;
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
    width: 100%;
}

.second-follow-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 20px;
    width: 100%;
}

.third-tips {
    display: inline-flex;
    font-size: 15px;
    font-family: Helvetica-Regular;
    font-weight: 400;
    margin-top: 5px;
    line-height: 30px;
    width: 100%;
    margin-left: 30px;
}

.button-submit {
    background: black;
    color: white;
    border-radius: 7px;
    margin-top: 10px;
}

>>> .van-dropdown-menu {
    width: 100%;
}

>>> .van-dropdown-menu__title {
    font-size: 14px;
}

>>> .van-dropdown-menu__item {
    justify-content: left;
}

>>> .van-dropdown-menu__bar {
    height: unset;
    box-shadow: unset;
}

>>> .van-dialog {
    border-radius: 5px;
}

>>> .birth-view .van-dropdown-menu__item {
    justify-content: right;
}

>>> .van-checkbox {
    /* margin-top: -10% */
}
>>> .van-field__label {
    color:#262626
}
</style>

<style lang="less" scoped>
.age .van-radio--horizontal {
    margin-bottom: 5px !important;
}

/deep/ .item1::before {
    position: absolute;
    left: -4px;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
}

/deep/ .item2::before {
    position: absolute;
    left: 33%;
    top: 0px;
    color: #ee0a24;
    font-size: 14px;
    content: "*";
}

.gender-style {
    width:95%;
    margin-left: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.birth-style {
    width:95%;
    margin-left: 16px;
    align-items: center;
    display: flex;
}
</style>

<style>
.noticeWidth {
    width: 100%;
}
</style>